<template>
  <div>
    <h3>Storia del viaggio {{ tripId }}</h3>
    <div class="loading" v-if="loading">Loading...</div>
    <div class="hello" v-if="!loading">
      <ul>
        <li v-for="event in events" :key="event.message">
          <div class="data">{{ timeToDate(event.time) }}</div>
          <div class="type">{{ event.type }}</div>
          <div class="data">{{ event.data }}</div>
          <hr>
        </li>
      </ul>
      <div>{{ info }}</div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios';
  import * as moment from 'moment';

  export default {
    name: 'TripHistory',
    props: {
      tripId: Number,
      baseApiUrl: String
    },
    data: function() {
      return {
        info: null,
        loading: true,
        errored: false,
        events: []
      }
    },
    mounted: function() {
      axios
        .get(this.baseApiUrl + 'trips/'+this.tripId+'/history')
        .then(response => {
          this.events = response.data.reverse()
        })
        .catch(error => {
          console.log(error)
          this.errored = true
        })
        .finally(() => 
          this.loading = false
        )
    },
    methods: {
      timeToDate: function(time) {
          return moment(time).format('DD/MM/YYYY hh:mm:ss ');
      }
    }
  }
</script>

<style scoped>
  li {
    background: 1px solid red !important;
    list-style: none;
  }

  button {
    position: relative;
    text-align: center;
    white-space: nowrap;
    outline: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    cursor: pointer;
    height: 30px;
    padding: 0 13px;
    color: #191919;
    font-weight: 400;
    
    
    border-radius: 4px;
    border: 1px solid #c5c5c5;
    border-top-color: #c5c5c5;
    border-bottom-color: #bcbcbc;
    background-color: #fafafa;
    background-image: -webkit-linear-gradient(top, #fafafa 2%, #efefef 98%);
    background-image: linear-gradient(to bottom,#fafafa 2%, #efefef 98%);
    -webkit-box-shadow: inset 0 1px 0 white, inset 0 -1px 0 #e7e7e7, 0 2px 3px rgba(0, 0, 0, 0.05);
    box-shadow: inset 0 1px 0 white, inset 0 -1px 0 #e7e7e7, 0 2px 3px rgba(0, 0, 0, 0.05);
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.05);

    margin-right: 10px;
  }
</style>

