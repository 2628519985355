<template>
  <div v-if="showWizard" class="trip-info checkin">
    <div>
      <div class="error-box" v-if="this.errored">Errore: {{ error }}</div>
      <!-- p>Questo viaggio è <span class="info-box">{{ parsedState }}</span></p -->
      <div v-if="driverVehicleDistance<driverRange" class="warning info-box">
        <p class="title">
          Check-in non possibile 
        </p>

        <div v-show="unknownStep==0" class="wiz-unknown-step-0">
          <div>
            <p>CONTROLLO INIZIO PRENOTAZIONE></p>
            <p>Chiedi al Driver a che ora ha tentato il check-in e confrontala con il dato presente qui di DATA INIZIO</p>
            <p>E' iniziata la prenotazione ?</p>
          </div>
          <div class="buttons-box">
            <button @click="nextUnknownStep()">SI</button>
            <button @click="finalUnknownStep()">NO</button>
          </div>
        </div>
        <div v-show="unknownStep==1" class="wiz-unknown-step-1">
          <div>
            <p>Chiedere al Driver il codice 6 cifre (codice PIN) che gli è stato inviato via email subito dopo il pagamento della prenotazione. Il PIN che il driver deve fornire è <strong>{{ trip.pin }}</strong>.</p>
            <p>Inoltre chiedi al driver i dati di patente e carta identità.</p>
            <p>Confronta il PIN e i documenti con le info presenti in questo pannello. L'identità è verificata?</p>
          </div>
          <div class="buttons-box">
            <button @click="nextUnknownStep()">SI, identità verificata</button>
            <button @click="preFinalUnknownStep('IDENTITA_NON_VERIFICATA')">NO, identità non verificata</button>
          </div>         
        </div>    
        <div v-show="unknownStep==2" class="wiz-unknown-step-2">
          <div>
            <p><strong>Identità verificata</strong></p>
            <p>Premendo il tasto qui sotto, attiverai la procedura di apertura dell'auto che durerà anche una ventina di secondi.</p>
            <VehicleCommands 
              v-bind:base-api-url="baseApiUrl" 
              v-bind:trip-id=trip.id 
              v-bind:vehicle-id="vehicle.id" 
              v-bind:open-button="true" 
              v-bind:close-button="false" 
              v-bind:open-button-enabled="true"
              v-bind:close-button-enabled="false" 
              @open-command-succeded="preFinalUnknownStep('APERTURA_VEICOLO')"
              @open-command-failed="nextUnknownStep"
              />
          </div>
        </div>
        
        <div v-show="unknownStep==3" class="wiz-unknown-step-3">
          <div>
            <p>Rimane solo la possibilità di aprire da remoto il veicolo.</p>
            <p>Si è verificato un errore nell'apertura del veicolo.<br/>Riprova la procedura di apertura del veicolo una seconda volta.</p>
            <VehicleCommands 
              v-bind:base-api-url="baseApiUrl" 
              v-bind:trip-id=trip.id 
              v-bind:vehicle-id="vehicle.id" 
              v-bind:open-button="true" 
              v-bind:close-button="false" 
              v-bind:open-button-enabled="true"
              v-bind:close-button-enabled="false" 
              @open-command-succeded="preFinalUnknownStep('APERTURA_VEICOLO')"
              @open-command-failed="nextUnknownStep"
              />
          </div>
        </div>

        <div v-show="unknownStep==4" class="wiz-unknown-step-4">
          <p><strong>Apertura NON riuscita.</strong></p>
          <p>Comunica al Driver che proverai a contattare l'Owner per risolvere il problema.</p>
          <p>Puoi chiudere la telefonata col Driver. Rimani in questa schermata per seguire il processo. Telefona all'Owner per chiedere se può consegnare al Driver l'auto e le chiavi direttamente (cioè a mano).</p>
          <p>L'owner è disponibile?</p>
          <div class="buttons-box">
            <button @click="preFinalUnknownStep('OWNER_DISPONIBILE')">Si</button>
            <button @click="nextUnknownStep()">No</button>
          </div>
        </div>

        <div v-show="unknownStep==5" class="wiz-unknown-step-5">
          <p>Se l'Owner non è disponibile, puoi attivare il servizio di ricerca di un'auto sostitutiva per il Driver (come da polizza 2019/10/3193909 addendum articolo 3.E.b ("prolungamento viaggio"). Il fallito check-in è configurato con un guasto.</p>
          <p>Il driver è residente oltre 50 km dalla posizione dell'auto?</p>
          <div class="buttons-box">
            <button @click="preFinalUnknownStep('RESIDENTE_OLTRE_50')">Si</button>
            <button @click="preFinalUnknownStep('RESIDENTE_ENTRO_50')">No</button>
          </div>
        </div>

        <div v-show="unknownStep==999" class="wiz-unknown-step-999">
          <div v-show="preFinalUnknownStepTextCode == 'IDENTITA_NON_VERIFICATA'">
            <p><strong>Identità non verificata</strong></p>
            <p>Comunica al Driver che non può accedere all'auto. Clicca su "Termina procedura". Avvisa Auting via email a support@auting.it.</p>         
          </div>

          <div v-show="preFinalUnknownStepTextCode == 'OWNER_DISPONIBILE'">
            <p>Se l'Owner è disponibile, digli di chiamare il driver e che si possono mettere d'accordo direttamente. Dovranno stampare e compilare assieme il documento "checklist" che l'Owner e il Driver hanno a disposizione nel loro account Auting nella sezione del viaggio. </p>          
          </div>

          <div v-show="preFinalUnknownStepTextCode == 'APERTURA_VEICOLO'">
            <p><strong>Apertura effettuata con successo.</strong></p>
            <p>Comunica al driver che gli arriverà fra pochi minuti una email con un modulo da compilare.</p> 
            <p>Deve compilarlo subito, è obbligatorio ed utile a lui / lei in caso di controversie per danni all'auto. Non è necessario che tu attenda la compilazione del modulo, puoi terminare la chiamata.</p>
            <div class="result-box" v-if="!errored && result!=null">
              {{ result }}
          </div>     
          </div>

          <div v-show="preFinalUnknownStepTextCode == 'RESIDENTE_ENTRO_50'">
            <p>Proseguimento del viaggio a carico del Conducente locatario residente entro 50 km dalla posizione dell'auto</p>
            <p>L'operatore comunicherà al Conducente che:</p>
            <ul>
              <li>la Centrale Operativa può segnalare al Conducente un autonoleggio presso cui è disponibile un veicolo per il Conducente. La prenotazione dovrà essere fatta dal Conducente direttamente presso il centro nolo.</li>
              <li>Auting rimborserà al Conducente l'intero importo della prenotazione Auting non conclusa, per maggiori informazioni sul rimborso il Conducente deve scrivere alla mail support@auting.it.</li>
              <li>Auting rimborserà al Conducente l'importo del taxi/mezzo di trasporto usato per raggiungere l'autonoleggio, per maggiori informazioni sul rimborso il Conducente deve scrivere alla mail support@auting.it.</li>
              <li>Auting rimborserà al Conducente la spesa sostenuta per prenotare il veicolo sostitutivo entro il limite complessivo di € 150. Per maggiori informazioni sul rimborso il Conducente deve scrivere alla mail support@auting.it.</li>
            </ul>
          </div>
          
          <div v-show="preFinalUnknownStepTextCode == 'RESIDENTE_OLTRE_50'">
            <p>Proseguimento del viaggio con auto sostitutiva fornita dalla Centrale Operativa - Conducente locatario residente oltre 50 km dalla posizione dell'auto.</p>
            <p>L'operatore comunicherà al Conducente che:</p>
            <ul>
              <li>la Centrale Operativa prenoterà un veicolo presso un autonoleggio convenzionato tenendone il costo a carico di Reale Mutua con il limite complessivo di € 150. L'eventuale importo extra è a carico del Driver che pagherà l'importo direttamente al centro nolo con carta di credito. Il requisito della carta di credito E' RICHIESTO DAL CENTRO NOLO ED ERA RICHIESTO ANCHE SU Auting nei termini di servizio della piattaforma. Se il Driver non possiede una carta di credito NON potrà godere dell'auto sostitutiva. Per maggiori informazioni sul rimborso il Conducente deve scrivere alla mail support@auting.it.</li>
              <li>la Centrale Operativa provvederà ad inviare un taxi sul luogo dell'immobilizzo, tenendone il costo a carico di Reale Mutua con il limite complessivo di € 50 (COME DA ART 3.1.D DELLA POLIZZA 3193909) , per permettere al Conducente di raggiungere l'autonoleggio.</li>
              <li>Auting rimborserà al Conducente l'intero importo della prenotazione Auting non conclusa. Per maggiori informazioni sul rimborso il Conducente deve scrivere alla mail support@auting.it.</li>
            </ul>
          </div>

          <div class="buttons-box">
              <button @click="finalUnknownStep()">Concludi assistenza</button>
            </div>       
        </div>     

        <div v-show="unknownStep==1000" class="wiz-unknown-step-1000">
          Procedura terminata, grazie.
        </div>
      </div>
      
      <div v-if="driverVehicleDistance>driverRange" class="warning info-box">
        <p class="title">
          Check-in non possibile per posizione non corretta del Driver
        </p>

        <div v-show="driverStep==0" class="wiz-driver-step-0">
          <p>Il Driver non può aprire l'auto con l'app perche risulta essere a circa <span class="state" v-bind:class="{ 'danger': driverVehicleDistance>driverRange }">
          {{ driverVehicleDistance }}</span> metri dall'auto.</p>
          <p>La distanza massima consentita è di <span class="state">{{ driverRange }}</span> metri.</p>
          <div class="buttons-box">
            <button @click="nextDriverStep()">CLICCA PER PROSEGUIRE</button>
          </div>
        </div>

        <div v-show="driverStep==1" class="wiz-driver-step-1">
          <div>
            Chiedere al driver se si trova di fronte al veicolo
          </div>
          <div class="buttons-box">
            <button @click="nextDriverStep()">SI</button>
            <button @click="goToDriverStep(8)">NO</button>
          </div>
        </div>

        <div v-show="driverStep==2" class="wiz-driver-step-2">
          <div>
            Chiedere al driver di 
            <ul>
              <li>verificare la connettività del telefono</li>
              <li>verificare l'attivazione della funzionalità di geolocalizzazione sul proprio telefono</li>
              <li>verificare la correttezza della propria posizione utilizzando Google Maps o Apple Maps</li>
              <li>infine riprovare ad effettuare la procedura di check-in riavviando l'app</li>
            </ul>
          </div>
          <div class="buttons-box">
            Dopo aver provato questi passi, il Driver riesce a procedere con il check-in?
            <button @click="finalDriverStep()">SI</button>
            <button @click="nextDriverStep()">NO</button>
          </div>
        </div>        
        
        <div v-show="driverStep==3" class="wiz-driver-step-3">
          <p><strong>Posizione NON riconosciuta.</strong></p>
          <p>Comunica al Driver che proverai a contattare l'Owner per risolvere il problema.</p>
          <p>Puoi chiudere la telefonata col Driver. Rimani in questa schermata per seguire il processo. Telefona all'Owner, per chiedere se può consegnare al Driver l'auto e le chiavi direttamente (cioè a mano).</p>
          <p>L'owner è disponibile?</p>
          <div class="buttons-box">
            <button @click="nextDriverStep()">Si</button>
            <button @click="goToDriverStep(5)">No</button>
          </div>
        </div>

        <div v-show="driverStep==4" class="wiz-driver-step-4">
          <p>Se l'Owner è disponibile, digli di chiamare il driver e che si possono mettere d'accordo direttamente. Dovranno stampare e compilare assieme il documento “checklist” che l'Owner e il Driver hanno a disposizione nel loro account Auting nella sezione del viaggio. </p>
          <div class="buttons-box">
            <button @click="finalDriverStep()">Concludi assistenza</button>
          </div> 
        </div>

        <div v-show="driverStep==5" class="wiz-driver-step-5">
          <p>Se l'Owner non è disponibile, puoi attivare il servizio di ricerca di un'auto sostitutiva per il Driver, come da polizza 2019/10/3193909 addendum articolo 3.E.b ("prolungamento viaggio"). Il fallito checkin è configurato come un guasto.</p>
          <p>Il driver è residente oltre 50 km dalla posizione dell'auto?</p>
          <div class="buttons-box">
            <button @click="nextDriverStep()">Si</button>
            <button @click="goToDriverStep(7)">No</button>
          </div>
        </div>

        <div v-show="driverStep==6" class="wiz-driver-step-6">
          <p>Proseguimento del viaggio con auto sostitutiva fornita dalla Centrale Operativa - Conducente locatario residente oltre 50 km dalla posizione dell'auto.</p>
          <p>L'operatore comunicherà al Conducente che:</p>
          <ul>
            <li>la Centrale Operativa può segnalare al Conducente un autonoleggio presso cui è disponibile un veicolo per il Conducente. La prenotazione dovrà essere fatta dal Conducente direttamente presso il centro nolo.</li>
            <li>Auting rimborserà al Conducente l'intero importo della prenotazione Auting non conclusa, per maggiori informazioni sul rimborso il Conducente deve scrivere alla mail support@auting.it.</li>
            <li>Auting rimborserà al Conducente l'importo del taxi/mezzo di trasporto usato per raggiungere l'autonoleggio, per maggiori informazioni sul rimborso il Conducente deve scrivere alla mail support@auting.it.</li>
            <li>Auting rimborserà al Conducente la spesa sostenuta per prenotare il veicolo sostitutivo entro il limite complessivo di € 150. Per maggiori informazioni sul rimborso il Conducente deve scrivere alla mail support@auting.it.</li>
          </ul>
          <div class="buttons-box">
            <button @click="finalDriverStep()">Concludi assistenza</button>
          </div> 
        </div>  

        <div v-show="driverStep==7" class="wiz-driver-step-7">
          <p>Proseguimento del viaggio a carico del Conducente locatario residente entro 50 km dalla posizione dell'auto</p>
          <p>L'operatore comunicherà al Conducente che:</p>
          <ul>
            <li>la Centrale Operativa può segnalare al Conducente un autonoleggio presso cui è disponibile un veicolo per il Conducente. La prenotazione dovrà essere fatta dal Conducente direttamente presso il centro nolo.</li>
            <li>Auting rimborserà al Conducente l'intero importo della prenotazione Auting non conclusa, per maggiori informazioni sul rimborso il Conducente deve scrivere alla mail support@auting.it.</li>
            <li>Auting rimborserà al Conducente l'importo del taxi/mezzo di trasporto usato per raggiungere l'autonoleggio, per maggiori informazioni sul rimborso il Conducente deve scrivere alla mail support@auting.it.</li>
            <li>Auting rimborserà al Conducente la spesa sostenuta per prenotare il veicolo sostitutivo entro il limite complessivo di € 150. Per maggiori informazioni sul rimborso il Conducente deve scrivere alla mail support@auting.it.</li>
          </ul>
          <div class="buttons-box">
            <button @click="finalDriverStep()">Concludi assistenza</button>
          </div>          
        </div>

        <div v-show="driverStep==8" class="wiz-driver-step-8">
          <div>
            Chiedere al driver di 
            <ul>
              <li>avvicinarsi a meno di {{ driverRange }} metri dal veicolo</li>
              <li>riprovare ad effettuare la procedura di check-in</li>
            </ul>
          </div>
          <div class="buttons-box">
            <button @click="finalDriverStep()">Puoi chiudere la telefonata</button>
          </div>    
        </div>       

        <div v-show="driverStep==1000" class="wiz-driver-step-1000">
          Procedura terminata, grazie.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import * as moment from 'moment';
  import VehicleCommands from './VehicleCommands.vue'

  const INITIAL_STEP = 0
  const PRE_FINAL_STEP = 999
  const FINAL_OK_STEP = 1000

  export default {
    name: 'TripWizardCheckIn',

    components: { 
      VehicleCommands
    },

    props: {
      baseApiUrl: String,
      trip: Object,
      tripState: Object,
      vehicle: Object,
      parkingRange: {
        type: Number,
        default: 1000
      },
      driverRange: {
        type: Number,
        default: 200
      }
    },

    data: function() {
      return {
        showWizard: true,
        driverStep: INITIAL_STEP,
        vehicleStep: INITIAL_STEP,
        unknownStep: INITIAL_STEP,
        preFinalUnknownStepTextCode: '',
        errored: false,
        error: '',        
      }
    },

    methods: {
      timeToDate: function(time) {
          return moment(time).format('DD/MM/YYYY HH:mm:ss ');
      },

      nextDriverStep: function() {
        this.driverStep++
      },

      goToDriverStep: function(step) {
        this.driverStep = step
      },      

      finalDriverStep: function() {
        this.$emit('problem-solved')
        this.driverStep = FINAL_OK_STEP
      },

      nextUnknownStep: function() {
        this.unknownStep++
      },

      preFinalUnknownStep: function(textCode) {
        this.unknownStep = PRE_FINAL_STEP
        this.preFinalUnknownStepTextCode = textCode

        if(textCode == 'APERTURA_VEICOLO'){
          this.sendEmailToDriver()
        }
      },

      finalUnknownStep: function() {
        this.$emit('problem-solved')
        this.unknownStep = FINAL_OK_STEP
      },

      handleDriverCloseCommandSucceded: function() {
        this.driverStep = FINAL_OK_STEP
      },

      handleDriverCloseCommandFailed: function() {
        this.driverStep++
      },

      distance2: function(lat1, lng1, lat2, lng2) {
        var p = 0.017453292519943295;    // Math.PI / 180
        var c = Math.cos;
        var a = 0.5 - c((lat2 - lat1) * p)/2 + 
                c(lat1 * p) * c(lat2 * p) * 
                (1 - c((lng2 - lng1) * p))/2;

        return 12742 * Math.asin(Math.sqrt(a)); // 2 * R; R = 6371 km
      },

      distance: function(lat1, lon1, lat2, lon2) {
        var R = 6371; // Radius of the earth in km
        var dLat = this.deg2rad(lat2-lat1);  // this.deg2rad below
        var dLon = this.deg2rad(lon2-lon1); 
        var a = 
          Math.sin(dLat/2) * Math.sin(dLat/2) +
          Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) * 
          Math.sin(dLon/2) * Math.sin(dLon/2)
          ; 
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
        var d = R * c; // Distance in km

        return (d * 1000).toFixed(0);
      },

      deg2rad: function(deg) {
        return deg * (Math.PI/180)
      },

      prepareEmailData: function() {
        return {
          from: 'support@auting.it',
          to: this.trip.driver.email,
          subject: 'Modulo di check-in di backup per Auting Connect',
          template: 'checkin_by_typeform',
          message: '',
          data: {
            'driver': {
              'name': this.trip.driver.name,
            },
            'vehicle' : {
              'owner': {
                'lastname': this.trip.owner.lastname,
                'name': this.trip.owner.name,
              },
              'make': this.vehicle.make,
              'model': this.vehicle.model,
              'plate': this.vehicle.plate,
            },
            'start': this.timeToDate(this.trip.reservedFrom),
            'end': this.timeToDate(this.trip.reservedUntil),
          }  
        }
      },

      sendEmailToDriver: async function() {
        this.errored = false
        this.proceeding = true

        try {
          const response = await axios({
            method: 'POST',
            url: 'http://54.155.236.233:9057/api/email',
            data: this.prepareEmailData(),
          })

          console.log(JSON.stringify(response))
        } catch (error) {
          const txtError = "l'invio dell'email al driver non è riuscito. Comunicare al driver questo link https://auting.it/ciform per compilare il modulo. "
          this.errored = true
          this.error = this.parseError(error,txtError)
        }
      },

      parseError: function(error, txtError) {
        console.warn(error.response)
        let errorTxt = ""

        if(txtError != '')
          errorTxt = txtError
        else 
          errorTxt = "L'operazione richiesta non è andata a buon fine."

        errorTxt = errorTxt + "(cod: "+error.response.status
        if (error.response.data && error.response.data.error) {
          errorTxt = errorTxt + " - " + error.response.data.error
        }
        errorTxt = errorTxt + ")"

        return errorTxt
      }    

    },
    
    /*
        this.parking.lat = parseFloat(response.data.initial.position.lat)
        this.parking.lng = parseFloat(response.data.initial.position.lng)
        this.vehicle.lat = parseFloat(response.data.current.position.lat)
        this.vehicle.lng = parseFloat(response.data.current.position.lng)
        this.driver.lat = parseFloat(response.data.driver.lat)
        this.driver.lng = parseFloat(response.data.driver.lng)
    */

    computed: {
      driverVehicleDistance: function() {
        if (!this.tripState || this.tripState == {} || !this.tripState.driver || !this.tripState.driver.lat || !this.tripState.current.position.lat) {
          return 'n/a'
        }

        const d = this.distance(this.tripState.driver.lat, this.tripState.driver.lng, this.tripState.current.position.lat, this.tripState.current.position.lng)
        if (d>1000) {
          this.$emit('driver-out-of-range')
        }

        return d
      },
      vehicleParkingDistance: function() {
        if (!this.tripState || this.tripState == {} || !this.tripState.driver || !this.tripState.driver.lat || !this.tripState.current.position.lat) {
          return 'n/a'
        }

        const d = this.distance(this.tripState.initial.position.lat, this.tripState.initial.position.lng, this.tripState.current.position.lat, this.tripState.current.position.lng)
        if (d>1000) {
          this.$emit('vehicle-out-of-range')
        }

        return d
      },
      parsedState: function() {
        if (!this.tripState.state || this.tripState == {}) {
          return 'sconosciuto'
        }

        switch (this.tripState.state) {
          case 'accepted':
            return 'accettato dal proprietario'
          case 'paid':
            return 'pagato dal conducente'
          case 'ongoing':
            return 'in corso'
          case 'closed':
            return 'terminato'
          default:
            return this.tripState.state
        }
      }

    }
  }
</script>

<style scoped>
  .trip-info {
    text-align: left;
    padding: 0;
  }
  .state {
    border: 1px solid green;
    padding: 3px 8px;
    border-radius: 8px;
    background-color: rgba(0,255,128,0.3);
    text-transform: uppercase;
    font-weight: bold;
    font-size: smaller;
  }
  .info-box {
    border: 1px solid green;
    padding: 1em;
    margin: 1em 0;
    border-radius: 8px;
    background-color: rgba(0,255,128,0.3);
  }
  .info-box .title {
    text-align: center;
    margin-top: 0.2em;
    text-transform: uppercase;
    font-weight: bold;
  }
  .info-box .buttons-box {
    margin: 1em 0 0;
  }
  .warning {
    background-color: rgba(255,255,0,0.2);
    color: black;
  }
  .danger {
    background-color: rgba(255,0,0,0.7);
    color: white;
  }
</style>

