<template>
  <div>
    <Modal
      v-show="isModalVisible"
      @close="closeModal"
      @proceed="proceedModal"
    >
      <template v-slot:header>{{ modalHeaderText }}</template>
      <template v-slot:body>
        {{ modalBodyText }}
        <div class="inputs-container">
          <label for="lat">
            Latitudine:
            <input type="text" v-model.number="lat" placeholder="44.4932256"/>
          </label>
          <label for="lng">
            Longitudine:
            <input type="text" v-model.number="lng" placeholder="11.3657386" />
          </label>
        </div>
      </template>
      <template v-slot:footer>{{ modalFooterText }}</template>
      <template v-slot:proceed>{{ modalProceedText }}</template>
      <template v-slot:cancel>{{ modalCancelText }}</template>
    </Modal>
    <div class="loading" v-if="loading">
      Loading...
    </div>
    <div v-else>
      <div class="trip-commands" v-if="!loading">
        <div class="buttons-container">
          <div v-if="this.checkinButton" class="button-container">
            <button @click="showCheckinModal" :disabled="checkinButtonDisabled">{{ proceeding ? 'attendi...' : "CHECK-IN DEL VIAGGIO" }}</button> 
          </div>
          <div v-if="this.checkoutButton" class="button-container">
            <button @click="showCheckoutModal" :disabled="checkoutButtonDisabled">{{ proceeding ? 'attendi...' : "CHECK-OUT DEL VIAGGIO" }}</button> 
          </div>
        </div>
      </div>
      <div class="trip-commands" v-if="!loading">
        <div class="buttons-container">
          <div v-if="this.checkinButton" class="button-container">
            <button @click="showFixCheckinModal" :disabled="checkinButtonDisabled">{{ proceeding ? 'attendi...' : "FIX CHECK-IN" }}</button> 
          </div>
          <div v-if="this.checkoutButton" class="button-container">
            <button @click="showFixCheckoutModal" :disabled="checkoutButtonDisabled">{{ proceeding ? 'attendi...' : "FIX CHECK-OUT" }}</button> 
            <label><strong>N.B.: non chiude il veicolo</strong></label>
          </div>
        </div>
      </div>
      <div class="error-box" v-if="errored">
        {{ error }}
      </div>
      <div class="result-box" v-if="!errored && result!=null">
        {{ result }}
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import Modal from './Modal.vue'

  export default {
    name: 'AdminTripCommands',
    
    components: {
      Modal,
    },

    props: {
      baseApiUrl: String,
      tripId: Number,
      vehicleId: Number,
      checkinButton: {
        type: Boolean,
        default: false
      },
      checkoutButton: {
        type: Boolean,
        default: true
      },
      checkinButtonEnabled: {
        type: Boolean,
        default: true
      },
      checkoutButtonEnabled: {
        type: Boolean,
        default: true
      }
    },

    data: function() {
      return {
        loading: true,
        proceeding: false,
        errored: false,
        error: '',
        result: null,
        isModalVisible: false,

        lat: null,
        lng: null,

        modalHeaderText: '',
        modalBodyText: '',
        modalFooterText: '',
        modalProceedText: '',
        modalCancelText: 'ANNULLA',
        modalClose: () => {},
        modalProceed: () => {}
      }
    },

    computed: {
      checkinButtonDisabled: function() {
        return (!this.checkinButtonEnabled || this.proceeding) 
          ? 'disabled' 
          : false
      }, 

      checkoutButtonDisabled: function() {
        return (!this.checkoutButtonEnabled || this.proceeding)
          ? 'disabled'
          : false
      }
    },

    mounted: function() {
      this.loading = false
      this.proceeding = false
    },

    methods: {
      showCheckinModal() {
        this.modalHeaderText = 'Check-in viaggio'
        this.modalBodyText = 'Premendo il tasto "check-in" qui sotto, chiederai al sistema di effettuare la procedura di check-in automatico del viaggio con relattiva apertura del veicolo.'
        this.modalFooterText = ''
        this.modalProceedText = 'CHECK-IN'
        this.modalClose = () => {}
        this.modalProceed = this.checkinTrip

        this.result = null
        this.error = null
        this.errored = false
        this.isModalVisible = true
      },

      showCheckoutModal() {
        this.modalHeaderText = 'Check-out viaggio'
        this.modalBodyText = 'Premendo il tasto "check-out" qui sotto, chiederai al sistema di effettuare la procedura di check-out automatico del viaggio con relativa chiusura del veicolo.'
        this.modalFooterText = ''
        this.modalProceedText = 'CHECK-OUT'
        this.modalClose = () => {}
        this.modalProceed = this.checkoutTrip

        this.result = null
        this.errored = false
        this.error = null
        this.isModalVisible = true
      },

      showFixCheckinModal() {
        this.modalHeaderText = 'Fix Check-in'
        this.modalBodyText = 'Premendo il tasto "fix check-in" qui sotto, chiederai al sistema di aggiornare lo stato del viaggio come se fosse stato effettuato il check-in, senza agire in alcun modo sul veicolo. Le coordinate inserite qui sotto saranno considerate per la riconsegna del veicolo.'
        this.modalFooterText = ''
        this.modalProceedText = 'FIX CHECK-IN'
        this.modalClose = () => {}
        this.modalProceed = this.fixCheckinTrip

        this.result = null
        this.error = null
        this.errored = false
        this.isModalVisible = true
      },

      showFixCheckoutModal() {
        this.modalHeaderText = 'Fix Check-out'
        this.modalBodyText = 'Premendo il tasto "fix check-out" qui sotto, chiederai al sistema di aggiornare lo stato del viaggio come se fosse stato effettuato il check-out, senza agire in alcun modo sul veicolo.'
        this.modalFooterText = ''
        this.modalProceedText = 'FIX CHECK-OUT'
        this.modalClose = () => {}
        this.modalProceed = this.fixCheckoutTrip

        this.result = null
        this.errored = false
        this.error = null
        this.isModalVisible = true
      },

      closeModal() {
        this.isModalVisible = false
      },

      proceedModal() {
        this.modalProceed()
        this.isModalVisible = false
      },

      prepareTripData: function(lat=44.4932256, lng=11.3657386) {
        return {
          id: this.tripId + '',
          mocked: false,
          tripId: this.tripId + '',
          vehicleId: this.vehicleId + '',
          driverPosition: {
            lat: lat || 44.4932256,
            lng: lng || 11.3657386,
            updatedAt: Date.now()
          }  
        }
      },
      
      checkinTrip: async function() {
        this.errored = false
        this.proceeding = true

        try {
          const response = await axios({
            method: 'PUT',
            url: this.baseApiUrl + 'admin/trips/' + this.tripId + '/checkin',
            data: this.prepareTripData()
          })

          this.result = this.parseResult(response.status)
          this.proceeding = false
          this.$emit('checkin-command-succeded');
        } catch (error) {
          this.proceeding = false
          this.errored = true
          this.error = this.parseError(error)
          this.$emit('checkin-command-failed');
        }
      },
      
      checkoutTrip: async function() {
        this.errored = false
        this.proceeding = true

        try {
          const response = await axios({
            method: 'PUT',
            url: this.baseApiUrl + 'admin/trips/' + this.tripId + '/checkout',
            data: this.prepareTripData()
          })

          this.result = this.parseResult(response.status)
          this.proceeding = false
          this.$emit('checkout-command-succeded');
        } catch (error) {
          this.proceeding = false
          this.errored = true
          this.error = this.parseError(error)
          this.$emit('checkout-command-failed');
        }
      },
      
      fixCheckinTrip: async function() {
        this.errored = false
        this.proceeding = true

        try {
          const response = await axios({
            method: 'PUT',
            url: this.baseApiUrl + 'admin/trips/' + this.tripId + '/fix-checkin',
            data: this.prepareTripData(this.lat, this.lng)
          })

          this.result = this.parseResult(response.status)
          this.proceeding = false
          this.$emit('fix-checkin-command-succeded');
        } catch (error) {
          this.proceeding = false
          this.errored = true
          this.error = this.parseError(error)
          this.$emit('fix-checkin-command-failed');
        }
      },
      
      fixCheckoutTrip: async function() {
        this.errored = false
        this.proceeding = true

        try {
          const response = await axios({
            method: 'PUT',
            url: this.baseApiUrl + 'admin/trips/' + this.tripId + '/fix-checkout',
            data: this.prepareTripData(this.lat, this.lng)
          })

          this.result = this.parseResult(response.status)
          this.proceeding = false
          this.$emit('fix-checkout-command-succeded');
        } catch (error) {
          this.proceeding = false
          this.errored = true
          this.error = this.parseError(error)
          this.$emit('fix-checkout-command-failed');
        }
      },

      parseResult: function(status) {
        switch (status) {
          case 200:
            return 'Comando inviato con successo. Verificare con il guidatore.'
        }
        return "Impossibile eseguire l'operazione richiesta"
      },

      parseError: function(error) {
        console.warn(error.response)

        let errorTxt = "L'operazione richiesta non è andata a buon fine. (cod: "+error.response.status
        if (error.response.data && error.response.data.error) {
          errorTxt = errorTxt + " - " + error.response.data.error
        }
        errorTxt = errorTxt + ")"

        return errorTxt
      }

    }
  }
</script>

<style>
  .trip-commands {
    padding: 1em;
    
  }
  .buttons-container {
    display: flex;
  }
  .inputs-container {
    display: flex;
    margin-top: 10px;
  }
  
  .button-container {
    flex: 2;
    display: flex;
    flex-direction: column;
  }

  button {
    position: relative;
    text-align: center;
    white-space: nowrap;
    outline: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    cursor: pointer;
    height: 30px;
    padding: 0 13px;
    color: #191919;
    font-weight: 400;
    
    
    border-radius: 4px;
    border: 1px solid #c5c5c5;
    border-top-color: #c5c5c5;
    border-bottom-color: #bcbcbc;
    background-color: #fafafa;
    background-image: -webkit-linear-gradient(top, #fafafa 2%, #efefef 98%);
    background-image: linear-gradient(to bottom,#fafafa 2%, #efefef 98%);
    -webkit-box-shadow: inset 0 1px 0 white, inset 0 -1px 0 #e7e7e7, 0 2px 3px rgba(0, 0, 0, 0.05);
    box-shadow: inset 0 1px 0 white, inset 0 -1px 0 #e7e7e7, 0 2px 3px rgba(0, 0, 0, 0.05);
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.05);

    margin-right: 10px;
  }

  input {
    line-height: 1em;
    width: 80%;

    text-align: center;

    padding: 5px 10px;
    border-radius: 4px;
    border: 1px solid #c5c5c5;
    border-top-color: #c5c5c5;
    border-bottom-color: #bcbcbc;
    background-color: #fafafa;
    background-image: -webkit-linear-gradient(top, #efefef 2%, #fafafa 98%);
    background-image: linear-gradient(to bottom,#efefef 2%, #fafafa 98%);
    -webkit-box-shadow: inset 0 1px 0 white, inset 0 -1px 0 #e7e7e7, 0 2px 3px rgba(0, 0, 0, 0.05);
    box-shadow: inset 0 1px 0 white, inset 0 -1px 0 #e7e7e7, 0 2px 3px rgba(0, 0, 0, 0.05);
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.05);
  }
</style>

