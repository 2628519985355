<template>
  <div v-if="showWizard" class="trip-info">
    <div>
      <!-- p>Questo viaggio è <span class="info-box">{{ parsedState }}</span></p -->
      <div v-if="vehicleParkingDistance<parkingRange && driverVehicleDistance<driverRange" class="warning info-box">
        <p class="title">
          Check-out non possibile 
        </p>

        <div v-show="unknownStep==0" class="wiz-driver-step-0">
          <p>Il veicolo risulta essere a circa 
            <span class="state" v-bind:class="{ 'danger': vehicleParkingDistance>parkingRange }">{{ vehicleParkingDistance }}</span> 
            metri dal punto di riconsegna.<br/>La distanza massima consentita è di {{ parkingRange }} metri.</p>
          <p>Il Driver risulta essere a circa 
            <span class="state" v-bind:class="{ 'danger': driverVehicleDistance>driverRange }">{{ driverVehicleDistance }}</span> 
            metri dal veicolo.<br/>La distanza massima consentita è di {{ driverRange }} metri.</p>
          <div class="buttons-box">
            <button @click="nextUnknownStep()">CLICCA PER PROSEGUIRE</button>
          </div>
        </div>
        
        <div v-show="unknownStep==1" class="wiz-unknown-step-1">
          <div>
            (1/3) Chiedere al Driver di 
            <ul>
              <li>verificare la connettività del telefono</li>
              <li>riavviare il telefono</li>
              <li>verificare che l'app auting si apra correttamente</li>
              <li>riprovare ad effettuare la procedura di check-out</li>
            </ul>
          </div>
          <div class="buttons-box">
            Il Driver è riuscito ad effettuare la procedura di check-out?
            <button @click="finalUnknownStep()">SI</button>
            <button @click="nextUnknownStep()">NO</button>
          </div>
        </div>
        
        <div v-show="unknownStep==2" class="wiz-unknown-step-2">
          <div>
            <p>Chiedere al Driver di prelevare i propri beni dall'auto e di chiudere correttamente tutte le portiere e il portellone del bagagliaio.</p>
            <p>Premendo il tasto qui sotto, attiverai la procedura di chiusura che durerà anche una ventina di secondi.</p>
            <VehicleCommands 
              v-bind:base-api-url="baseApiUrl" 
              v-bind:trip-id=trip.id 
              v-bind:vehicle-id="vehicle.id" 
              v-bind:open-button="false" 
              v-bind:close-button="true" 
              v-bind:open-button-enabled="false"
              v-bind:close-button-enabled="true" 
              @close-command-succeded="finalUnknownStep"
              @close-command-failed="nextUnknownStep"
              />
          </div>
        </div>
        
        <div v-show="unknownStep==3" class="wiz-unknown-step-3">
          <div>
            <p>Rimane solo la possibilità di chiudere da remoto il veicolo.</p>
            <p>Si è verificato un errore nella chiusura del veicolo.<br/>Riprova la procedura di chiusura del veicolo una seconda volta.</p>
            <VehicleCommands 
              v-bind:base-api-url="baseApiUrl" 
              v-bind:trip-id=trip.id 
              v-bind:vehicle-id="vehicle.id" 
              v-bind:open-button="false" 
              v-bind:close-button="true" 
              v-bind:open-button-enabled="false"
              v-bind:close-button-enabled="true" 
              @close-command-succeded="finalUnknownStep"
              @close-command-failed="goForDeposit"
              />
          </div>
        </div>
        
        <div v-show="unknownStep==1000" class="wiz-unknown-step-1000">
          Procedura terminata, grazie.
        </div>
      </div>

      <div v-if="vehicleParkingDistance>parkingRange" class="danger info-box">
        <p class="title">
          Check-out non possibile per posizione non corretta del veicolo
        </p>

        <div v-show="vehicleStep==0" class="wiz-driver-step-0">
          <p>Il veicolo risulta essere a circa 
            <span class="state" v-bind:class="{ 'danger': vehicleParkingDistance>parkingRange }">{{ vehicleParkingDistance }}</span> 
            metri dal punto di riconsegna</p>
          <div class="buttons-box">
            <button @click="nextVehicleStep()">CLICCA PER PROSEGUIRE</button>
          </div>
        </div>
        
        <div v-show="vehicleStep==1" class="wiz-vehicle-step-1">
          <div>
            (1/3) Chiedere al Driver di 
            <ul>
              <li>portare il veicolo all'interno dell'area delimitata dal cerchio verde, il più vicino al luogo dal quale è stata prelevato il veicolo</li>
              <li>riprovare ad effettuare la procedura di check-out una volta dentro l'area</li>
            </ul>
          </div>
          <div class="buttons-box">
            Il Driver è in condizione di spostare l'auto?
            <button @click="finalVehicleStep()">SI</button>
            <button @click="nextVehicleStep()">NO</button>
          </div>
        </div>
        
        <div v-show="vehicleStep==2" class="wiz-vehicle-step-2">
          <p>Predisporre la procedura per il deposito cliccando il bottone qui sotto</p>
          <div class="buttons-box">
            <button @click="goForDeposit()">PROCEDI CON DEPOSITO</button>
          </div>
        </div>
        
        <div v-show="vehicleStep==1000" class="wiz-vehicle-step-1000">
          Procedura terminata, grazie.
        </div>
      </div>

      <div v-if="driverVehicleDistance>driverRange && vehicleParkingDistance<=parkingRange" class="warning info-box">
        <p class="title">
          Check-out non possibile per posizione non corretta del Driver
        </p>

        <div v-show="driverStep==0" class="wiz-driver-step-0">
          <p>Il Driver non può chiudere l'auto con l'app perche risulta essere a circa <span class="state" v-bind:class="{ 'danger': driverVehicleDistance>driverRange }">
          {{ driverVehicleDistance }}</span> metri dall'auto.</p>
          <p>La distanza massima consentita è di <span class="state">{{ driverRange }}</span> metri.</p>
          <div class="buttons-box">
            <button @click="nextDriverStep()">CLICCA PER PROSEGUIRE</button>
          </div>
        </div>

        <div v-show="driverStep==1" class="wiz-driver-step-1">
          <div>
            Chiedere al driver se si trova di fronte al veicolo
          </div>
          <div class="buttons-box">
            <button @click="nextDriverStep()">SI</button>
            <button @click="goToDriverStep(5)">NO</button>
          </div>
        </div>

        <div v-show="driverStep==2" class="wiz-driver-step-2">
          <div>
            Chiedere al driver di      
            <ul>
              <li>verificare l'attivazione della funzionalità di geolocalizzazione sul proprio telefono</li>
              <li>verificare la correttezza della propria posizione utilizzando Google Maps o Apple Maps</li>
              <li>infine riprovare ad effettuare la procedura di check-out</li>
            </ul>
          </div>
          <div class="buttons-box">
            Il Driver è riuscito ad effettuare la procedura di check-out?
            <button @click="finalDriverStep()">SI</button>
            <button @click="nextDriverStep()">NO</button>
          </div>
        </div>        

        <div v-show="driverStep==3" class="wiz-driver-step-3">
          <div>
            <p>Rimane solo la possibilità di chiudere da remoto il veicolo.</p>
            <p>Chiedere al driver di prelevare i propri beni dall'auto e di chiudere correttamente tutte le portiere e il portellone del bagagliaio.</p>
            <p>Premendo il tasto qui sotto, attiverai la procedura di chiusura che durerà anche una ventina di secondi.</p>
            <VehicleCommands 
              v-bind:base-api-url="baseApiUrl" 
              v-bind:trip-id=trip.id 
              v-bind:vehicle-id="vehicle.id" 
              v-bind:open-button="false" 
              v-bind:close-button="true" 
              v-bind:open-button-enabled="false"
              v-bind:close-button-enabled="true" 
              @close-command-succeded="finalDriverStep"
              @close-command-failed="nextDriverStep"
              />
          </div>
        </div>
        
        <div v-show="driverStep==4" class="wiz-driver-step-4">
          <div>
            <p>Si è verificato un errore nella chiusura del veicolo.<br/>Riprova la procedura di chiusura del veicolo una seconda volta.</p>
            <VehicleCommands 
              v-bind:base-api-url="baseApiUrl" 
              v-bind:trip-id=trip.id 
              v-bind:vehicle-id="vehicle.id" 
              v-bind:open-button="false" 
              v-bind:close-button="true" 
              v-bind:open-button-enabled="false"
              v-bind:close-button-enabled="true" 
              @close-command-succeded="finalDriverStep"
              @close-command-failed="goForDeposit"
              />
          </div>
        </div>

        <div v-show="driverStep==5" class="wiz-driver-step-5">
          <div>
            Chiedere al driver di 
            <ul>
              <li>avvicinarsi a meno di {{ driverRange }} metri dal veicolo</li>
              <li>riprovare ad effettuare la procedura di check-out</li>
            </ul>
          </div>
          <div class="buttons-box">
            <button @click="finalDriverStep()">Puoi chiudere la telefonata</button>
          </div>    
        </div>
        
        <div v-show="driverStep==1000" class="wiz-driver-step-1000">
          Procedura terminata, grazie.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import VehicleCommands from './VehicleCommands.vue'

  const INITIAL_STEP = 0
  const FINAL_OK_STEP = 1000

  export default {
    name: 'TripWizard',

    components: { 
      VehicleCommands
    },

    props: {
      baseApiUrl: String,
      trip: Object,
      tripState: Object,
      vehicle: Object,
      parkingRange: {
        type: Number,
        default: 1000
      },
      driverRange: {
        type: Number,
        default: 200
      }
    },

    data: function() {
      return {
        showWizard: true,
        driverStep: INITIAL_STEP,
        vehicleStep: INITIAL_STEP,
        unknownStep: INITIAL_STEP
      }
    },

    methods: {
      nextDriverStep: function() {
        this.driverStep++
      },

      goToDriverStep: function(step) {
        this.driverStep = step
      },      

      finalDriverStep: function() {
        this.$emit('problem-solved')
        this.driverStep = FINAL_OK_STEP
      },

      nextVehicleStep: function() {
        this.vehicleStep++
      },

      finalVehicleStep: function() {
        this.$emit('problem-solved')
        this.vehicleStep = FINAL_OK_STEP
      },

      nextUnknownStep: function() {
        this.unknownStep++
      },

      finalUnknownStep: function() {
        this.$emit('problem-solved')
        this.unknownStep = FINAL_OK_STEP
      },

      goForDeposit: function() {
        this.$emit('go-for-deposit')
        this.showWizard = false
      },

      handleDriverCloseCommandSucceded: function() {
        this.driverStep = FINAL_OK_STEP
      },

      handleDriverCloseCommandFailed: function() {
        this.driverStep++
      },

      handleVehicleCloseCommandSucceded: function() {
        this.vehicleStep = FINAL_OK_STEP
      },

      handleVehicleCloseCommandFailed: function() {
        this.vehicleStep++
      },

      distance2: function(lat1, lng1, lat2, lng2) {
        var p = 0.017453292519943295;    // Math.PI / 180
        var c = Math.cos;
        var a = 0.5 - c((lat2 - lat1) * p)/2 + 
                c(lat1 * p) * c(lat2 * p) * 
                (1 - c((lng2 - lng1) * p))/2;

        return 12742 * Math.asin(Math.sqrt(a)); // 2 * R; R = 6371 km
      },

      distance: function(lat1, lon1, lat2, lon2) {
        var R = 6371; // Radius of the earth in km
        var dLat = this.deg2rad(lat2-lat1);  // this.deg2rad below
        var dLon = this.deg2rad(lon2-lon1); 
        var a = 
          Math.sin(dLat/2) * Math.sin(dLat/2) +
          Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) * 
          Math.sin(dLon/2) * Math.sin(dLon/2)
          ; 
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
        var d = R * c; // Distance in km

        return (d * 1000).toFixed(0);
      },

      deg2rad: function(deg) {
        return deg * (Math.PI/180)
      }

    },
    
    /*
        this.parking.lat = parseFloat(response.data.initial.position.lat)
        this.parking.lng = parseFloat(response.data.initial.position.lng)
        this.vehicle.lat = parseFloat(response.data.current.position.lat)
        this.vehicle.lng = parseFloat(response.data.current.position.lng)
        this.driver.lat = parseFloat(response.data.driver.lat)
        this.driver.lng = parseFloat(response.data.driver.lng)
    */

    computed: {
      driverVehicleDistance: function() {
        if (!this.tripState || this.tripState == {} || !this.tripState.driver || !this.tripState.driver.lat || !this.tripState.current.position.lat) {
          return 'n/a'
        }

        const d = this.distance(this.tripState.driver.lat, this.tripState.driver.lng, this.tripState.current.position.lat, this.tripState.current.position.lng)
        if (d>1000) {
          this.$emit('driver-out-of-range')
        }

        return d
      },
      vehicleParkingDistance: function() {
        if (!this.tripState || this.tripState == {} || !this.tripState.driver || !this.tripState.driver.lat || !this.tripState.current.position.lat) {
          return 'n/a'
        }

        const d = this.distance(this.tripState.initial.position.lat, this.tripState.initial.position.lng, this.tripState.current.position.lat, this.tripState.current.position.lng)
        if (d>1000) {
          this.$emit('vehicle-out-of-range')
        }

        return d
      },
      parsedState: function() {
        if (!this.tripState.state || this.tripState == {}) {
          return 'sconosciuto'
        }

        switch (this.tripState.state) {
          case 'accepted':
            return 'accettato dal proprietario'
          case 'paid':
            return 'pagato dal conducente'
          case 'ongoing':
            return 'in corso'
          case 'closed':
            return 'terminato'
          default:
            return this.tripState.state
        }
      }

    }
  }
</script>

<style scoped>
  .trip-info {
    text-align: left;
    padding: 0;
  }
  .state {
    border: 1px solid green;
    padding: 3px 8px;
    border-radius: 8px;
    background-color: rgba(0,255,128,0.3);
    text-transform: uppercase;
    font-weight: bold;
    font-size: smaller;
  }
  .info-box {
    border: 1px solid green;
    padding: 1em;
    margin: 1em 0;
    border-radius: 8px;
    background-color: rgba(0,255,128,0.3);
  }
  .info-box .title {
    text-align: center;
    margin-top: 0.2em;
    text-transform: uppercase;
    font-weight: bold;
  }
  .info-box .buttons-box {
    margin: 1em 0 0;
  }
  .warning {
    background-color: rgba(255,255,0,0.2);
    color: black;
  }
  .danger {
    background-color: rgba(255,0,0,0.7);
    color: white;
  }
</style>

