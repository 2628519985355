<template>
  <div>
    <div class="error-box" v-if="this.errored">Errore: {{ error }}</div>
    <h2 v-show="!this.errored">TARGA DEL VEICOLO: {{ vehicle.plate }}</h2>
    <!-- div>INFO: {{trip}}</div -->
    <PageInfos v-show="state=='intro' && !this.errored" 
      @show-cs-connect="showAll($event)"
      @hide-cs-connect="hideAll"
      />
    <div class='data-box wizard' v-if="useWizard && vehicle.id>0" v-show="state=='ready' && !errored && !completed">
      <TripWizard 
        v-if="!this.errored && this.useWizard && !this.showCheckIn"
        v-show="!this.errored && this.useWizard"
        v-bind:base-api-url="baseApiUrl" 
        v-bind:trip=trip
        v-bind:trip-state=trip.state
        v-bind:vehicle="vehicle" 
        v-bind:parking-range=1000 
        v-bind:driver-range=200
        @vehicle-out-of-range="handleVehicleOutOfRange"
        @driver-out-of-range="handleDriverOutOfRange"
        @go-for-deposit="handleGoForDeposit"
        @problem-solved="handleProblemSolved"
        />

        <TripWizardCheckIn 
        v-if="!this.errored && this.useWizard && this.showCheckIn"
        v-show="!this.errored && this.useWizard"
        v-bind:base-api-url="baseApiUrl" 
        v-bind:trip=trip
        v-bind:trip-state=trip.state
        v-bind:vehicle="vehicle" 
        v-bind:parking-range=1000 
        v-bind:driver-range=200
        @vehicle-out-of-range="handleVehicleOutOfRange"
        @driver-out-of-range="handleDriverOutOfRange"
        @go-for-deposit="handleGoForDeposit"
        @problem-solved="handleProblemSolved"
        />

      <DepositForm 
        v-show="this.showForm"
        v-bind:trip-id=tripId 
        v-bind:vehicle-id="vehicle.id" 
        @deposit-form-succeded="handleDepositFormSucceded"
        />
    </div>
    <div class='data-box admin' v-if="!useWizard && vehicle.id>0" v-show="state=='ready' && !errored && !completed">
      <AdminVehicleCommands 
        v-if="!this.useWizard && !this.readOnly"
        v-show="!this.useWizard && !this.readOnly"
        v-bind:base-api-url="baseApiUrl" 
        v-bind:vehicle-id="vehicle.id" 
        v-bind:open-button="true" 
        v-bind:close-button="true" 
        v-bind:open-button-enabled="true"
        v-bind:close-button-enabled="true" 
        @close-command-failed="handleCloseCommandFailed"
        @open-command-failed="handleOpenCommandFailed"
        />
      <AdminTripCommands 
        v-if="!this.useWizard && !this.readOnly"
        v-show="!this.useWizard && !this.readOnly"
        v-bind:base-api-url="baseApiUrl" 
        v-bind:trip-id="trip.id" 
        v-bind:vehicle-id="vehicle.id" 
        v-bind:checkin-button="true" 
        v-bind:checkout-button="true" 
        v-bind:checkin-button-enabled="true"
        v-bind:checkout-button-enabled="true" 
        @checkout-command-failed="handleCheckoutCommandFailed"
        @checkin-command-failed="handleCheckinCommandFailed"
        @fix-checkout-command-failed="handleFixCheckoutCommandFailed"
        @fix-checkin-command-failed="handleFixCheckinCommandFailed"
        />
    </div>
    <div class='data-box common' v-if="vehicle.id>0" v-show="state=='ready' && !errored && !completed">
      <VehicleCommands 
        v-if="!this.errored && this.showCommands && this.useWizard && !this.readOnly"
        v-show="!this.errored && this.showCommands && this.useWizard && !this.readOnly"
        v-bind:base-api-url="baseApiUrl" 
        v-bind:trip-id=tripId 
        v-bind:vehicle-id="vehicle.id" 
        v-bind:open-button="!useWizard" 
        v-bind:close-button="true" 
        v-bind:open-button-enabled="false"
        v-bind:close-button-enabled="true" 
        @close-command-failed="handleCloseCommandFailed"
        @open-command-failed="handleOpenCommandFailed"
        />
      <VehicleMapInfos 
        v-if="!this.showForm" 
      />
      <VehicleMap 
        v-if="vehicle.lat!=0 && vehicle.lng!=0 && this.trip.state!=null && !this.showForm" 
        v-bind:driver-lat="this.driver.lat" 
        v-bind:driver-lng="this.driver.lng" 
        v-bind:vehicle-lat="this.vehicle.lat" 
        v-bind:vehicle-lng="this.vehicle.lng" 
        v-bind:parking-lat="this.parking.lat" 
        v-bind:parking-lng="this.parking.lng" 
        v-bind:parking-range=1000 
        v-bind:driver-range=200
        />
      <TripHistory 
        v-if="!this.errored && !this.useWizard" 
        v-bind:base-api-url="baseApiUrl" 
        v-bind:trip-id=this.tripId
        />
    </div>
    <div v-show="state=='ready' && completed" class="info-box">
      <p>Procedura completata, grazie</p>
    </div>
    <div v-show="state=='outro'" class="info-box">
      <p>Gestire l'assistenza stradale secondo la polizza n° 2019/10/3193909.</p>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import PageInfos from './components/PageInfos.vue'
import VehicleCommands from './components/VehicleCommands.vue'
import AdminVehicleCommands from './components/AdminVehicleCommands.vue'
import AdminTripCommands from './components/AdminTripCommands.vue'
import VehicleMapInfos from './components/VehicleMapInfos.vue'
import VehicleMap from './components/VehicleMap.vue'
import TripWizard from './components/TripWizard.vue'
import TripHistory from './components/TripHistory.vue'
import DepositForm from './components/DepositForm.vue'
import TripWizardCheckIn from './components/TripWizardCheckIn.vue';

export default {
  name: 'App',
  components: {
    PageInfos,
    AdminVehicleCommands,
    AdminTripCommands,
    VehicleCommands,
    VehicleMapInfos,
    VehicleMap,
    TripWizard,
    TripHistory,
    DepositForm,
    TripWizardCheckIn
},
  props: {
    baseApiUrl: {
      type: String,
      default: 'http://localhost:3000'
    },
    tripId: {
      type: Number,
      default: 1
    },
    useWizard: {
      type: Boolean,
      default: true
    },
    readOnly: {
      type: Boolean,
      default: true
    },
  },
  data: function() {
    return {
      driver: {
        lat: 0.0,
        lng: 0.0
      },
      parking: {
        lat: 0.0,
        lng: 0.0
      },
      vehicle: {
        id: null,
        lat: 0.0,
        lng: 0.0,
        plate: "loading..."
      },
      loading: true,
      state: 'intro',
      errored: false,
      error: '',
      showForm: false,
      completed: false,
      showCommands: true,
      showCheckIn: false,
      showCheckOut: false,
      trip: {
        state: {}
      }
    }
  },
  methods: {
    showAll: function(wizardToShow) {
      this.state = 'ready'
      if(wizardToShow == 'check-in'){
        this.showCheckIn = true
      }else if(wizardToShow == 'check-out'){
        this.showCheckOut = true
      }

      console.log('showCheckIn: ' + this.showCheckIn + ' showCheckOut: ' + this.showCheckOut)
    },

    hideAll: function() {
      this.state = 'outro'
    },

    handleVehicleOutOfRange: function() {
      this.showCommands = false
    },

    handleDriverOutOfRange: function() {
      this.showCommands = false
    },

    handleCloseCommandFailed: function() {
    },

    handleOpenCommandFailed: function() {
    },

    handleCheckinCommandFailed: function() {
    },

    handleCheckoutCommandFailed: function() {
    },

    handleFixCheckinCommandFailed: function() {
    },

    handleFixCheckoutCommandFailed: function() {
    },

    handleProblemSolved: function() {
      this.completed = true
    },

    handleGoForDeposit: function() {
      this.showForm = true
    },

    handleDepositFormSucceded: function() {
      this.showForm = false
      this.completed = true
    },

    parseTripErrors: function(status, error) {
      switch(status) {
        case 404:
          return 'Viaggio non trovato'
        default:
          return 'Errore sconosciuto: '+JSON.stringify(error)
      }
    },

    parseTripStateErrors: function(status, error) {
      switch(status) {
        case 404:
          return 'Viaggio non keyless'
        default:
          return 'Errore sconosciuto: '+JSON.stringify(error)
      }
    },

    parseVehicleErrors: function(status, error) {
      switch(status) {
        case 404:
          return 'Veicolo non trovato per il viaggio'
        default:
          return 'Errore sconosciuto: '+JSON.stringify(error)
      }
    }
  },
  mounted: async function() {
    if (!this.tripId) {
      this.errored = true
      this.error = 'Specificare un viaggio'
      return
    }

    try {
      const response = await axios({
        method: 'GET',
        url: this.baseApiUrl + 'trips/'+this.tripId
      });

      this.trip = response.data
      this.trip.state = {}
      this.vehicle.id = parseInt(response.data.vehicleId)
      this.loading = false
    } catch (error) {
      console.error(error)
      this.vehicle.plate = ''
      this.loading = false
      this.errored = true
      this.error = this.parseTripErrors(error.response.status, error.response.statusText)
    }


    if (!this.errored) {
      try {
        const response = await axios({
          method: 'GET',
          url: this.baseApiUrl + 'vehicles/'+this.vehicle.id
        })
  
        this.vehicle.plate = response.data.plate
        this.loading = false
      } catch (error) {
        // Handle Error Here
        console.log(error)
        this.loading = false
        this.errored = true
        this.error = this.parseVehicleErrors(error.response.status, error.response.statusText)
      }
    }

    if (!this.errored) {
      try {
        const tripResponse = await axios({
          method: 'GET',
          url: this.baseApiUrl + 'trips/'+this.tripId+'/state'
        });

        this.trip.state = tripResponse.data
        this.parking.lat = parseFloat(tripResponse.data.initial.position.lat)
        this.parking.lng = parseFloat(tripResponse.data.initial.position.lng)
        this.driver.lat = parseFloat(tripResponse.data.driver.lat)
        this.driver.lng = parseFloat(tripResponse.data.driver.lng)

        const vehicleResponse = await axios({
          method: 'GET',
          url: this.baseApiUrl + 'vehicles/'+this.vehicle.id+'/state'
        });

        this.vehicle.lat = parseFloat(vehicleResponse.data.lat)
        this.vehicle.lng = parseFloat(vehicleResponse.data.lng)
        this.loading = false
      } catch (error) {
        console.error(error)
        this.loading = false
        this.errored = true
        this.error = this.parseTripStateErrors(error.response.status, error.response.statusText)
      }
    }

  console.log(this.useWizard)
    if (!this.useWizard) {
      this.showAll()
    }

  }
}
</script>

<style>
html {
  height: 900px;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.error-box {
  background: red;
  color: white;
  padding: 1em;
  font-size: 1em;
  margin-block: 1em;
}
.result-box {
  background: green;
  color: white;
  padding: 1em;
  font-size: 1em;
  margin-block: 1em;
}
hr {
  border-top: 1px solid lightgray;
  margin: 1em;
}
</style>
