<template>
  <div class="deposit-form">
    <h3>Deposito veicolo</h3>
    <p>Non è stato possibile chiudere il veicolo attraverso la procedura remota, percui è necessario attivare le procedure Blue Assistance di deposito.</p>
    <p>Riportare qui di seguito le informazioni relative al deposito dove verrà inviato il veicolo.</p>
    <p><b>N.B.: il Driver deve rimanere nei pressi del veicolo fino all'arrivo del mezzo di soccorso.</b></p>
    <form @submit="checkForm"
      action="https://vuejs.org/"
      method="post"
    >
      <div v-if="errors.length" class="error-box danger">
        <b>Correggere questi errori:</b>
        <ul>
          <li v-for="error in errors" v-bind:key="error">{{ error }}</li>
        </ul>
      </div>
      <div v-if="sent" class="result-box">
        <b>Dati inviati correttamente</b>
      </div>

      <div v-if="!sent">
        <p>
          <label />
          <b>Dati del deposito</b>
        </p>

        <p>
          <label for="name">Nome</label>
          <input id="name" v-model="name" type="text" name="name" >
        </p>

        <p>
          <label for="address">Indirizzo</label>
          <input id="address" v-model="address" type="text" name="address" >
        </p>

        <p>
          <label for="city">Città</label>
          <input id="city" v-model="city" type="text" name="city" >
        </p>

        <p>
          <label for="issue">Note</label>
          <textarea id="issue" v-model="issue" name="issue"></textarea>
        </p>

        <p>
          <button type="submit" value="Submit" >INVIA DATI</button>
        </p>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  
  name: 'DepositForm',

  props: {
      baseApiUrl: String,
      tripId: Number,
      vehicleId: Number,
  },

  data: function() {
    return {
      sent: false,
      errors: [],
      name: null,
      address: null,
      city: null,
      issue: null,
    }
  },

  methods:{
    checkForm: function (e) {
      e.preventDefault();
      this.errors = [];

      if (this.name && this.address && this.city) {

      /*
      fetch(apiUrl + encodeURIComponent(this.name))
        .then(async res => {
          if (res.status === 204) {
            alert('OK');
          } else if (res.status === 400) {
            let errorResponse = await res.json();
            this.errors.push(errorResponse.error);
          }
        });
      */
        this.sent = true;
        this.$emit('deposit-form-succeded')
        
        return false;
      }

      if (!this.name) {
        this.errors.push('Nome del reposito richiesto.');
      }
      if (!this.address) {
        this.errors.push('Indirizzo del deposito richiesto')
      }
      if (!this.city) {
        this.errors.push('Città del deposito richiesta')
      }

    }
  }
}
</script>

<style scoped>
  .deposit-form {
    background-color: rgba(220, 220, 220, 0.3);
    text-align: left;
    padding: 1em 2em;
  }

  label {
    font-size: 13px;
    font-weight: 400;
    white-space: nowrap;
    width: 150px;
    display: inline-block;
  }

  input,textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
    margin: 0;
    font: inherit;
    font-weight: 400;
    line-height: normal;
    height: 30px;
    border-radius: 4px;
    padding: 3px 7px;
    border: 1px solid #c5c5c5;
    background: white;
    color: #474747;
    -webkit-box-shadow: inset 0 1px 0 #f7f7f7, 0 1px 0 rgba(255, 255, 255, 0.1);
    box-shadow: inset 0 1px 0 #f7f7f7, 0 1px 0 rgba(255, 255, 255, 0.1);
    -webkit-transition: box-shadow 180ms, border 180ms;
    -moz-transition: box-shadow 180ms, border 180ms;
    transition: box-shadow 180ms, border 180ms;
    width: 250px;
  }

  textarea {
    min-width: 400px;
    min-height: 100px;
  }
</style>
