<template>
    <transition name="modal-fade">
        <div class="modal-backdrop">
            <div class="modal">
            <header class="modal-header">
                <slot name="header">
                    This is the default title!
                </slot>
            </header>

            <section class="modal-body">
                <slot name="body">
                This is the default body!
                </slot>
            </section>

            <footer class="modal-footer">
                <slot name="footer">
                    This is the default footer!
                </slot>
                <button type="button" class="btn-proceed" @click="proceed" >
                    <slot name="proceed">
                        Proceed button
                    </slot>
                </button>
                <button type="button" class="btn-cancel" @click="close">
                    <slot name="cancel">
                        Cancel
                    </slot>
                </button>
            </footer>
            </div>
        </div>
    </transition>
</template>

<script>
    export default {
        name: 'Modal',

        data() {
            return {
                showModal: false
            }
        },

        methods: {
            proceed() {
                this.$emit('proceed');
            },
            close() {
                this.$emit('close');
            },
        },
    }
</script>


<style scoped>
    .modal-backdrop {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.3);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 100;
    }

    .modal {
        background: #FFFFFF;
        box-shadow: 2px 2px 20px 1px;
        overflow-x: auto;
        display: flex;
        flex-direction: column;
        min-width: 200px;
        max-width: 300px;
    }

    .modal-header,
    .modal-footer {
        padding: 15px;
        display: flex;
    }

    .modal-header {
        position: relative;
        border-bottom: 1px solid #eeeeee;
        color: #4AAE9B;
        justify-content: space-between;
    }

    .modal-footer {
        display: flex;
        flex: 2;
        flex-direction: row;
        border-top: 1px solid #eeeeee;
        justify-content: flex-end;
    }

    .modal-footer .btn-proceed {
        background-image: linear-gradient(to bottom,#ff3d3d 2%, #c00000 98%);
        border: 1px solid #ff3d3d;
        box-shadow: inset 0 1px 0 #ff3d3d, inset 0 -1px 0 #ff3d3d, 0 2px 3px rgba(0, 0, 0, 0.05);
        color: white;
    }

    .modal-body {
        position: relative;
        padding: 20px 10px;
        color: black;
    }
    
    .modal-fade-enter,
    .modal-fade-leave-to {
        opacity: 0;
    }

    .modal-fade-enter-active,
    .modal-fade-leave-active {
        transition: opacity .5s ease;
    }
</style>