<template>
  <div class="map-infos">
    <h4>LEGENDA</h4>
    <ul>
      <li>
        <img src="https://maps.gstatic.com/mapfiles/ms2/micons/cabs.png" alt="auto" />: posizione del veicolo
      </li>
      <li>
        <b>Area verde</b>: il veicolo deve essere parcheggiato all'interno di questa area per poter procedere con il check-out.<br/>Cercare di parcheggiare il veicolo il più possibile verso il centro dell'area, ovvero la posizione da cui è stato prelevato.
      </li>
      <li>
        <img src="https://maps.gstatic.com/mapfiles/ms2/micons/man.png" alt="auto" />: posizione del Driver
      </li>
      <li>
        <b>Area blu</b>: il Driver deve trovarsi all'interno di questa area per poter effettuare il check-out.<br/>Al di fuori di questa area, il Driver non potrà interagire con il veicolo attraverso l'app.
      </li>
    </ul>
    <!--
    <p class="error-box" v-show="vehicleLat==0 && vehicleLng==0">Le coordinate geografiche del veicolo non sono disponibili, impossibile visualizzare il veicolo sulla mappa</p>
    <p class="error-box" v-show="driverLat==0 && driverLng==0">Le coordinate geografiche del Driver non sono disponibili, impossibile visualizzare il Driver sulla mappa</p>
    <p class="error-box" v-show="parkingLat==0 && parkingLng==0">Le coordinate geografiche di riconsegna del veicolo non sono disponibili, impossibile visualizzare la zona di riconsegna sulla mappa</p>
    -->
  </div>
</template>

<script>
  export default {
    name: 'VehicleMap',

/*
    props: {
      driverLat: Number,
      driverLng: Number,
      vehicleLat: Number,
      vehicleLng: Number,
      parkingLat: Number,
      parkingLng: Number
    }
*/
  }
</script>

<style scoped>
  .map-infos {
    width: 100vw;
    background-color: rgb(241, 241, 241);
    border: 1px solid gray;
    border-radius: 8px 8px 0 0;
    padding: 1em;
  }

  .map-infos ul {
    display: flex;
    padding: 0 1em;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  .map-infos li[data-v-1ae47455] {
    list-style: none;
    text-align: left;
    box-sizing: border-box;
    max-width: 50%;
    min-width: 50%;
    width: 50%;
    display: block;
    margin-bottom: 1em;
  }

  .map-infos li img {
    height: 18px;
  }

  .error-box {
    border-radius: 8px;
    text-align: left;
  }

</style>


