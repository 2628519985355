<template>
  <div class="Map">
  </div>
</template>

<script>
  import gmapsInit from '../utils/gmaps';

  export default {
    name: 'VehicleMap',

    props: {
      driverLat: Number,
      driverLng: Number,
      vehicleLat: Number,
      vehicleLng: Number,
      parkingLat: Number,
      parkingLng: Number,
      parkingRange: {
        type: Number,
        default: 1000
      },
      driverRange: {
        type: Number,
        default: 200
      }
    },

    data: function() {
      return {
        loading: true,
        errored: false,
        result: null,
        map: null
      };
    },

    mounted: async function() {
      try {
        const google = await gmapsInit();
        const driverPosition = { lat: this.driverLat, lng: this.driverLng }
        const vehiclePosition = { lat: this.vehicleLat, lng: this.vehicleLng }
        const parkingPosition = { lat: this.parkingLat, lng: this.parkingLng }
        const map = new google.maps.Map(this.$el, {
          mapTypeId: google.maps.MapTypeId.ROADMAP,
          center: vehiclePosition,
          zoom: 14,
        })

        const vehicleMarker = new google.maps.Marker({
          position: vehiclePosition,
          map: map,
          icon: "https://maps.gstatic.com/mapfiles/ms2/micons/cabs.png"
        })

        const driverMarker = new google.maps.Marker({
          position: driverPosition,
          map: map,
          icon: "https://maps.gstatic.com/mapfiles/ms2/micons/man.png"
        })

        const c1 = new google.maps.Circle({
          strokeColor: "#0000FF",
          strokeOpacity: 0.3,
          strokeWeight: 1,
          fillColor: "#0000FF",
          fillOpacity: 0.05,
          map,
          center: vehiclePosition,
          radius: this.driverRange,
        })

        const c2 = new google.maps.Circle({
          strokeColor: "#00bb00",
          strokeOpacity: 0.5,
          strokeWeight: 1,
          fillColor: "#00FF00",
          fillOpacity: 0.15,
          map,
          center: parkingPosition,
          radius: this.parkingRange,
        })

        var latlngbounds = new google.maps.LatLngBounds();
        latlngbounds.union(c1.getBounds());
        latlngbounds.union(c2.getBounds());
        latlngbounds.extend(driverMarker.getPosition());
        latlngbounds.extend(vehicleMarker.getPosition());

        map.setCenter(latlngbounds.getCenter(), map.getBoundsZoomLevel(latlngbounds));
        //map.fitBounds(latlngbounds);
        //map.panToBounds(latlngbounds);

        this.map = map

      } catch (error) {
        console.error(error);
      }
      this.loading = false;
    },

    methods: {
      distance: (lat1, lng1, lat2, lng2) => {
        var p = 0.017453292519943295;    // Math.PI / 180
        var c = Math.cos;
        var a = 0.5 - c((lat2 - lat1) * p)/2 + 
                c(lat1 * p) * c(lat2 * p) * 
                (1 - c((lng2 - lng1) * p))/2;

        return 12742 * Math.asin(Math.sqrt(a)); // 2 * R; R = 6371 km
      }
    }
  }
</script>

<style scoped>
  .Map {
    width: 100vw;
    height: 400px;
    background-color: lightgray;
    border: 1px solid gray;
    border-top: 0px solid transparent;
    border-radius: 0 0 8px 8px
  }

</style>

