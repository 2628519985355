<template>
  <div class="page-infos">
    <p>Che tipo di assistenza è stata richiesta?</p> 
    <div class="buttons-box">
      <button @click="handleGoCsConnect('check-in')">CHECK-IN NON RIUSCITO DA APP</button>
      <button @click="handleGoCsConnect('check-out')">CHECK-OUT NON RIUSCITO DA APP</button>
      <button @click="handleHideCsConnect">ASSISTENZA STRADALE</button>
    </div>
  </div>
</template>

<script>
export default {
  
  name: 'PageInfos',

  methods: {
    handleGoCsConnect: function(wizardToShow) {
      this.$emit('show-cs-connect', wizardToShow)
    },

    handleHideCsConnect: function() {
      this.$emit('hide-cs-connect')
    }
  }
}
</script>

<style scoped>
  .page-infos {
    background-color: lightgray;
    border: 1px solid gray;
    text-align: left;
    padding: 1em 2em;
    border-radius: 8px;
  }

</style>
