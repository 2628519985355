import { createApp } from 'vue'
import App from './App.vue'

var url_string = window.location.href
var url = new URL(url_string)


const baseApiUrl = process.env.VUE_APP_API_URL
const tripId = parseInt(url.searchParams.get('trip')) ?? 0

const requestUser = url.searchParams.get('key')
//const requestConfirm = url.searchParams.get('value')

let useWizard = true;
let readOnly = true;
let authenticated = false;

if (requestUser=='admin'/* && requestConfirm=='fbcfc8516dda8f51430902de46ebbfbf'*/) {
    authenticated = true;
    useWizard = false;
    readOnly = false;
}
if (requestUser=='operator'/* && requestConfirm=='ead4093f74108f793e9f7043d5a0112f'*/) {
    authenticated = true;
    useWizard = false;
}
if (requestUser=='blue_assistance'/* && requestConfirm=='6d67ef2555e16ff1581fb49a17b8193c'*/) {
    authenticated = true;
    readOnly = false;
}


function canBeOpened() {
    try {
        return true || window.self !== window.top;
    } catch (e) {
        return true;
    }
}

if (authenticated && canBeOpened()) {
    console.log('baseApiUrl', baseApiUrl)
    createApp( App, { baseApiUrl: baseApiUrl, tripId: tripId, useWizard: useWizard, readOnly: readOnly } ).mount('#app')
}