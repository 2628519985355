<template>
  <div>
    <Modal
      v-show="isModalVisible"
      @close="closeModal"
      @proceed="proceedModal"
    >
      <template v-slot:header>{{ modalHeaderText }}</template>
      <template v-slot:body>{{ modalBodyText }}</template>
      <template v-slot:footer>{{ modalFooterText }}</template>
      <template v-slot:proceed>{{ modalProceedText }}</template>
      <template v-slot:cancel>{{ modalCancelText }}</template>
    </Modal>
    <div class="loading" v-if="loading">
      Loading...
    </div>
    <div v-else>
      <div class="vehicle-commands" v-if="!loading">
        <div class="buttons-container">
          <div v-if="this.openButton" class="button-container">
            <button @click="showOpenModal" :disabled="openButtonDisabled">{{ proceeding ? 'attendi...' : "APRI IL VEICOLO" }}</button> 
          </div>
          <div v-if="this.closeButton" class="button-container">
            <button @click="showCloseModal" :disabled="closeButtonDisabled">{{ proceeding ? 'attendi...' : "CHIUDI IL VEICOLO" }}</button> 
          </div>
        </div>
      </div>
      <div class="error-box" v-if="errored">
        {{ error }}
      </div>
      <div class="result-box" v-if="!errored && result!=null">
        {{ result }}
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import Modal from './Modal.vue'

  export default {
    name: 'AdminVehicleCommands',
    
    components: {
      Modal,
    },

    props: {
      baseApiUrl: String,
      vehicleId: Number,
      openButton: {
        type: Boolean,
        default: false
      },
      closeButton: {
        type: Boolean,
        default: true
      },
      openButtonEnabled: {
        type: Boolean,
        default: true
      },
      closeButtonEnabled: {
        type: Boolean,
        default: true
      }
    },

    data: function() {
      return {
        loading: true,
        proceeding: false,
        errored: false,
        error: '',
        result: null,
        isModalVisible: false,

        modalHeaderText: '',
        modalBodyText: '',
        modalFooterText: '',
        modalProceedText: '',
        modalCancelText: 'ANNULLA',
        modalClose: () => {},
        modalProceed: () => {}
      }
    },

    computed: {
      openButtonDisabled: function() {
        return (!this.openButtonEnabled || this.proceeding) 
          ? 'disabled' 
          : false
      }, 

      closeButtonDisabled: function() {
        return (!this.closeButtonEnabled || this.proceeding)
          ? 'disabled'
          : false
      }
    },

    mounted: function() {
      this.loading = false
      this.proceeding = false
    },

    methods: {
      showOpenModal() {
        this.modalHeaderText = 'Apertura veicolo'
        this.modalBodyText = 'Premendo il tasto "apri" qui sotto, chiederai al sistema di attivare la procedura di apertura automatica del veicolo.'
        this.modalFooterText = ''
        this.modalProceedText = 'APRI'
        this.modalClose = () => {}
        this.modalProceed = this.openVehicle

        this.result = null
        this.error = null
        this.errored = false
        this.isModalVisible = true
      },

      showCloseModal() {
        this.modalHeaderText = 'Chiusura veicolo'
        this.modalBodyText = 'Premendo il tasto "chiudi" qui sotto, chiederai al sistema di attivare la procedura di chiusura automatica del veicolo.'
        this.modalFooterText = ''
        this.modalProceedText = 'CHIUDI'
        this.modalClose = () => {}
        this.modalProceed = this.closeVehicle

        this.result = null
        this.errored = false
        this.error = null
        this.isModalVisible = true
      },

      closeModal() {
        this.isModalVisible = false
      },

      proceedModal() {
        this.modalProceed()
        this.isModalVisible = false
      },

      prepareVehicleData: function() {
        return {
          id: this.vehicleId + '',
          mocked: false,
          vehicleId: this.vehicleId + '',
          driverPosition: {
            lat: 44.4932256,
            lng: 11.3657386,
            updatedAt: Date.now()
          }  
        }
      },

      prepareTripData: function() {
        return {
          id: this.id + '',
          mocked: false,
          vehicleId: this.vehicleId + '',
          driverPosition: {
            lat: 44.4932256,
            lng: 11.3657386,
            updatedAt: Date.now()
          }  
        }
      },
      
      openVehicle: async function() {
        this.errored = false
        this.proceeding = true

        try {
          const response = await axios({
            method: 'PUT',
            url: this.baseApiUrl + 'admin/vehicles/' + this.vehicleId + '/unlock',
            data: this.prepareVehicleData()
          })

          this.result = this.parseResult(response.status)
          this.proceeding = false
          this.$emit('open-command-succeded');
        } catch (error) {
          this.proceeding = false
          this.errored = true
          this.error = this.parseError(error)
          this.$emit('open-command-failed');
        }

      },
      
      closeVehicle: async function() {
        this.errored = false
        this.proceeding = true

        try {
          const response = await axios({
            method: 'PUT',
            url: this.baseApiUrl + 'admin/vehicles/' + this.vehicleId + '/lock',
            data: this.prepareVehicleData()
          })

          this.result = this.parseResult(response.status)
          this.proceeding = false
          this.$emit('close-command-succeded');
        } catch (error) {
          this.proceeding = false
          this.errored = true
          this.error = this.parseError(error)
          this.$emit('close-command-failed');
        }

      },
      
      parseResult: function(status) {
        switch (status) {
          case 200:
            return 'Comando inviato con successo. Verificare con il guidatore.'
        }
        return "Impossibile eseguire l'operazione richiesta"
      },

      parseError: function(error) {
        console.warn(error.response)

        let errorTxt = "L'operazione richiesta non è andata a buon fine. (cod: "+error.response.status
        if (error.response.data && error.response.data.error) {
          errorTxt = errorTxt + " - " + error.response.data.error
        }
        errorTxt = errorTxt + ")"

        return errorTxt
      }

    }
  }
</script>

<style>
  .vehicle-commands {
    padding: 1em;
    
  }
  .buttons-container {
    display: flex;
  }
  
  .button-container {
    flex: 2;
    display: flex;
    flex-direction: column;
}

  button {
    position: relative;
    text-align: center;
    white-space: nowrap;
    outline: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    cursor: pointer;
    height: 30px;
    padding: 0 13px;
    color: #191919;
    font-weight: 400;
    
    
    border-radius: 4px;
    border: 1px solid #c5c5c5;
    border-top-color: #c5c5c5;
    border-bottom-color: #bcbcbc;
    background-color: #fafafa;
    background-image: -webkit-linear-gradient(top, #fafafa 2%, #efefef 98%);
    background-image: linear-gradient(to bottom,#fafafa 2%, #efefef 98%);
    -webkit-box-shadow: inset 0 1px 0 white, inset 0 -1px 0 #e7e7e7, 0 2px 3px rgba(0, 0, 0, 0.05);
    box-shadow: inset 0 1px 0 white, inset 0 -1px 0 #e7e7e7, 0 2px 3px rgba(0, 0, 0, 0.05);
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.05);

    margin-right: 10px;
  }
</style>

